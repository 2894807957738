import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Avatar, Box, Divider, Drawer, Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useAuth from '../../hooks/useAuth';
import PlusIcon from '../../icons/Plus';
import MenuIcon from '../../icons/Menu';
import SunIcon from '../../icons/Sun';
import EyeIcon from '../../icons/Eye';
import UsersIcon from '../../icons/Users';
import Archive from '../../icons/Archive';
import UserIcon from '../../icons/User';
import Mail from '../../icons/Mail';
import PreviewIcon from '@material-ui/icons/Preview';
import ManuBookIcon from '@material-ui/icons/MenuBook';
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import GamepadIcon from '@material-ui/icons/Gamepad';

const sectionsFree = [
  {
    title: 'navigation',
    items: [
      {
        title: 'Übersicht',
        path: '/dashboard',
        icon: <MenuIcon fontSize="small" />
      },
      {
        title: 'Account',
        path: '/dashboard/account',
        icon: <UserIcon fontSize="small" />
      },
      {
        title: 'Übungen',
        icon: <PlusIcon fontSize="small" />,
        children: [
          {
            title: 'Übungen für alle',
            path: '/dashboard/products'
          },
          {
            title: 'Meine Übungen',
            path: '/dashboard/myproducts'
          },
          {
            title: 'Übung anlegen',
            path: '/dashboard/createproduct'
          }
        ]
      },
      {
        title: 'ViCo Material',
        icon: <SunIcon fontSize="small" />,
        children: [
          {
            title: 'Zahlen',
            children: [
              {
                title: 'Finde die Zahlen',
                path: '/dashboard/findnumbers'
              }
            ]
          },
          {
            title: 'Buchstaben & Worte',
            children: [
              {
                title: 'Enigma',
                path: '/dashboard/hcdecoding'
              }
            ]
          },
          {
            title: 'Bilaterale Integration'
          }
        ]
      },
      {
        title: 'ViCo Tests',
        path: '/dashboard/tests',
        icon: <GamepadIcon fontSize="small" />,
        children: [
          {
            title: 'Reaction Arrows',
            path: '/dashboard/testssimplearrowreaction'
          },
          {
            title: 'Color Words',
            path: '/dashboard/testscolorwords'
          },
          {
            title: 'Speedreading Level 1',
            path: '/dashboard/testsreadspeedfirstlevel'
          }
        ]
      },
      {
        title: 'eyeStudents',
        icon: <UsersIcon fontSize="small" />,
      },
      {
        title: 'Visualhygiene',
        path: '/dashboard/visualhygiene',
        icon: <PreviewIcon fontSize="small" />
      },
      {
        title: 'Leitfaden',
        path: '/dashboard/leitfaden',
        icon: <ImportContactsIcon fontSize="small" />
      },
      {
        title: 'Kontakt',
        path: '/dashboard/kontakt',
        icon: <Mail fontSize="small" />
      },
      {
        title: 'Rechtliches',
        path: '/dashboard/impressum',
        icon: <Archive fontSize="small" />
      },
      {
        title: 'FAQ',
        path: '/dashboard/faq',
        icon: <EyeIcon fontSize="small" />
      },
    ]
  }
];
const sectionsTrainer = [
  {
    title: 'navigation',
    items: [
      {
        title: 'Übersicht',
        path: '/dashboard',
        icon: <MenuIcon fontSize="small" />
      },
      {
        title: 'Account',
        path: '/dashboard/account',
        icon: <UserIcon fontSize="small" />
      },
      {
        title: 'Übungen',
        icon: <PlusIcon fontSize="small" />,
        children: [
          {
            title: 'Übungen für alle',
            path: '/dashboard/products'
          },
          {
            title: 'Meine Übungen',
            path: '/dashboard/myproducts'
          },
          {
            title: 'Übung anlegen',
            path: '/dashboard/createproduct'
          }
        ]
      },
      {
        title: 'ViCo Material',
        icon: <SunIcon fontSize="small" />,
        children: [
          {
            title: 'Zahlen',
            children: [
              {
                title: 'Asterix Code',
                path: '/dashboard/asterixcode'
              },
              {
                title: 'Caju',
                path: '/dashboard/calculatejump'
              },
              {
                title: 'Finde die Zahlen',
                path: '/dashboard/findnumbers'
              },
              {
                title: 'Tap the numbers',
                path: '/dashboard/comparenumbers'
              },
              {
                title: 'Welche Zahlen fehlen',
                path: '/dashboard/missingnumbers'
              }
            ]
          },
          {
            title: 'Buchstaben & Worte',
            children: [
              {
                title: 'Asterix Code',
                path: '/dashboard/asterixcode'
              },
              {
                title: 'Enigma',
                path: '/dashboard/hcdecoding'
              },
              {
                title: 'Finde die Wörter',
                path: '/dashboard/findwords'
              },
              {
                title: 'Scan & Jump',
                path: '/dashboard/findabc'
              }
            ]
          },
          {
            title: 'Bilaterale Integration',
            children: [
              {
                title: 'Farben einkreisen',
                path: '/dashboard/findcircles'
              },
              {
                title: 'Klatschen & Stampfen',
                path: '/dashboard/klatschenstampfen'
              },
              {
                title: 'Liegende Acht',
                path: '/dashboard/liegendeacht'
              }
            ]
          }
        ]
      },
      {
        title: 'ViCo Tests',
        path: '/dashboard/tests',
        icon: <GamepadIcon fontSize="small" />,
        children: [
          {
            title: 'Reaction Arrows',
            path: '/dashboard/testssimplearrowreaction'
          },
          {
            title: 'Color Words',
            path: '/dashboard/testscolorwords'
          },
          {
            title: 'Speedreading Level 1',
            path: '/dashboard/testsreadspeedfirstlevel'
          },
          {
            title: 'Speedreading Level 2',
            path: '/dashboard/testsreadspeedsecondlevel'
          }
        ]
      },
      {
        title: 'eyeStudents',
        path: '/dashboard/students',
        icon: <UsersIcon fontSize="small" />,
        children: [
          {
            title: 'Meine eyeStudents',
            path: '/dashboard/students'
          },
          {
            title: 'eyeStudent anlegen',
            path: '/dashboard/createstudent'
          }
        ]
      },
      {
        title: 'ViCo Wiki',
        path: '/dashboard/wiki',
        icon: <ManuBookIcon fontSize="small" />
      },
      {
        title: 'Visualhygiene',
        path: '/dashboard/visualhygiene',
        icon: <PreviewIcon fontSize="small" />
      },
      {
        title: 'Leitfaden',
        path: '/dashboard/leitfaden',
        icon: <ImportContactsIcon fontSize="small" />
      },
      {
        title: 'Kontakt',
        path: '/dashboard/kontakt',
        icon: <Mail fontSize="small" />
      },
      {
        title: 'Rechtliches',
        path: '/dashboard/impressum',
        icon: <Archive fontSize="small" />
      },
      {
        title: 'FAQ',
        path: '/dashboard/faq',
        icon: <EyeIcon fontSize="small" />
      },
    ]
  }
];
const sectionsTrainerPro = [
  {
    title: 'navigation',
    items: [
      {
        title: 'Übersicht',
        path: '/dashboard',
        icon: <MenuIcon fontSize="small" />
      },
      {
        title: 'Account',
        path: '/dashboard/account',
        icon: <UserIcon fontSize="small" />
      },
      {
        title: 'Übungen',
        icon: <PlusIcon fontSize="small" />,
        children: [
          {
            title: 'Übungen für alle',
            path: '/dashboard/products'
          },
          {
            title: 'Meine Übungen',
            path: '/dashboard/myproducts'
          },
          {
            title: 'Übung anlegen',
            path: '/dashboard/createproduct'
          }
        ]
      },
      {
        title: 'ViCo Material',
        icon: <SunIcon fontSize="small" />,
        children: [
          {
            title: 'Zahlen',
            children: [
              {
                title: 'Asterix Code',
                path: '/dashboard/asterixcode'
              },
              {
                title: 'Caju',
                path: '/dashboard/calculatejump'
              },
              {
                title: 'Finde die Zahlen',
                path: '/dashboard/findnumbers'
              },
              {
                title: 'Tap the numbers',
                path: '/dashboard/comparenumbers'
              },
              {
                title: 'Welche Zahlen fehlen',
                path: '/dashboard/missingnumbers'
              }
            ]
          },
          {
            title: 'Buchstaben & Worte',
            children: [
              {
                title: 'Asterix Code',
                path: '/dashboard/asterixcode'
              },
              {
                title: 'Enigma',
                path: '/dashboard/hcdecoding'
              },
              {
                title: 'Finde die Wörter',
                path: '/dashboard/findwords'
              },
              {
                title: 'Scan & Jump',
                path: '/dashboard/findabc'
              }
            ]
          },
          {
            title: 'Bilaterale Integration',
            children: [
              {
                title: 'Farben einkreisen',
                path: '/dashboard/findcircles'
              },
              {
                title: 'Klatschen & Stampfen',
                path: '/dashboard/klatschenstampfen'
              },
              {
                title: 'Liegende Acht',
                path: '/dashboard/liegendeacht'
              }
            ]
          }
        ]
      },
      {
        title: 'ViCo Tests',
        path: '/dashboard/tests',
        icon: <GamepadIcon fontSize="small" />,
        children: [
          {
            title: 'Reaction Arrows',
            path: '/dashboard/testssimplearrowreaction'
          },
          {
            title: 'Color Words',
            path: '/dashboard/testscolorwords'
          },
          {
            title: 'Speedreading Level 1',
            path: '/dashboard/testsreadspeedfirstlevel'
          },
          {
            title: 'Speedreading Level 2',
            path: '/dashboard/testsreadspeedsecondlevel'
          }
        ]
      },
      {
        title: 'eyeStudents',
        path: '/dashboard/students',
        icon: <UsersIcon fontSize="small" />,
        children: [
          {
            title: 'Meine eyeStudents',
            path: '/dashboard/students'
          },
          {
            title: 'eyeStudent anlegen',
            path: '/dashboard/createstudent'
          }
        ]
      },
      {
        title: 'ViCo Wiki',
        path: '/dashboard/wiki',
        icon: <ManuBookIcon fontSize="small" />
      },
      {
        title: 'Visualhygiene',
        path: '/dashboard/visualhygiene',
        icon: <PreviewIcon fontSize="small" />
      },
      {
        title: 'Leitfaden',
        path: '/dashboard/leitfaden',
        icon: <ImportContactsIcon fontSize="small" />
      },
      {
        title: 'Kontakt',
        path: '/dashboard/kontakt',
        icon: <Mail fontSize="small" />
      },
      {
        title: 'Rechtliches',
        path: '/dashboard/impressum',
        icon: <Archive fontSize="small" />
      },
      {
        title: 'FAQ',
        path: '/dashboard/faq',
        icon: <EyeIcon fontSize="small" />
      }
    ]
  }
];
const sectionsAdmin = [
  {
    title: 'navigation',
    items: [
      {
        title: 'Übersicht',
        path: '/dashboard',
        icon: <MenuIcon fontSize="small" />
      },
      {
        title: 'Account',
        path: '/dashboard/account',
        icon: <UserIcon fontSize="small" />
      },
      {
        title: 'Übungen',
        icon: <PlusIcon fontSize="small" />,
        children: [
          {
            title: 'Übung anlegen (Admin)',
            path: '/dashboard/createadminproduct'
          },
          {
            title: 'Übungen für alle (Admin)',
            path: '/dashboard/productsadmin'
          }
        ]
      }
    ]
  }
];
const sectionsOpen = [
  {
    title: 'navigation',
    items: [
      {
        title: 'Übersicht',
        path: '/dashboard',
        icon: <MenuIcon fontSize="small" />
      },
      {
        title: 'Account',
        path: '/dashboard/account',
        icon: <UserIcon fontSize="small" />
      },
      {
        title: 'Übungen',
        icon: <PlusIcon fontSize="small" />,
      },
      {
        title: 'ViCo Material',
        icon: <SunIcon fontSize="small" />,
        children: [
          {
            title: 'Zahlen',
            children: [
              {
                title: 'Finde die Zahlen',
                path: '/dashboard/findnumbers'
              }
            ]
          },
          {
            title: 'Buchstaben & Worte',
            children: [
              {
                title: 'Enigma',
                path: '/dashboard/hcdecoding'
              }
            ]
          },
          {
            title: 'Bilaterale Integration'
          }
        ]
      },
      {
        title: 'ViCo Tests',
        path: '/dashboard/tests',
        icon: <GamepadIcon fontSize="small" />,
        children: [
          {
            title: 'Reaction Arrows',
            path: '/dashboard/testssimplearrowreaction'
          }
        ]
      },
      {
        title: 'eyeStudents',
        icon: <UsersIcon fontSize="small" />
      },
      {
        title: 'Visualhygiene',
        path: '/dashboard/visualhygiene',
        icon: <PreviewIcon fontSize="small" />
      },
      {
        title: 'Kontakt',
        path: '/dashboard/kontakt',
        icon: <Mail fontSize="small" />
      },
      {
        title: 'Rechtliches',
        path: '/dashboard/impressum',
        icon: <Archive fontSize="small" />
      },
      {
        title: 'FAQ',
        path: '/dashboard/faq',
        icon: <EyeIcon fontSize="small" />
      }
    ]
  }
];

const DashboardSidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { user } = useAuth();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);
  let sections;
  if (user.groups.includes('Admin')) {
    sections = sectionsAdmin;
  } else if (user.groups.includes('TrainerPro')) {
    sections = sectionsTrainerPro;
  } else if (user.groups.includes('Trainer')) {
    sections = sectionsTrainer;
  } else if (user.groups.includes('Open')) {
    sections = sectionsOpen;
  } else if (user.groups.includes('Free')) {
    sections = sectionsFree;
  } else {
    sections = sectionsOpen;
  }

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              borderRadius: 1,
              display: 'flex',
              overflow: 'hidden',
              p: 2
            }}
          >
            <RouterLink to="/dashboard/account">
              <Avatar
                src={user.avatar}
                sx={{
                  cursor: 'pointer',
                  height: 48,
                  width: 48
                }}
              />
            </RouterLink>
            <Box sx={{ ml: 2 }}>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                {user.prename}
              </Typography>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                Mitgliedschaft
                <br />
                {user.groups[0]}
                {' '}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3
                }
              }}
              {...section}
            />
          ))}
        </Box>
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            height: 'calc(100% - 64px) !important',
            top: '64px !Important',
            width: 280
          }
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: 280
        }
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default DashboardSidebar;
